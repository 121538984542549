"use client"

import { useEffect } from "react"
import { usePathname } from "next/navigation"
import {
  House,
  LayoutDashboard,
  LogIn,
  LogOut,
  NotebookPen,
  Store,
} from "lucide-react"

import { useHeader } from "@/components/supervvisor/header-nav/header-context"

export function HeaderSetter({ children }: { children: React.ReactNode }) {
  const { setTitle, setItems, setActivePath } = useHeader()
  const pathname = usePathname()

  useEffect(() => {
    setTitle("Supervvisor")
    setItems([
      {
        label: "Home",
        href: "/home",
        icon: House,
      },
      {
        label: "Products",
        href: "/products",
        icon: Store,
      },
      {
        label: "Login",
        href: "/api/auth/login",
        icon: LogIn,
      },
      {
        label: "Sign Up",
        href: "/join",
        icon: NotebookPen,
      },
      {
        label: "Dashboard",
        href: "/dashboard",
        icon: LayoutDashboard,
      },
      {
        label: "Logout",
        href: "/api/auth/logout",
        icon: LogOut,
      },
    ])
    setActivePath(pathname)
  }, [setTitle, setItems, setActivePath, pathname])

  return children
}
